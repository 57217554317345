import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './StepMenu.module.scss';
import BasicRegistration from "../Registration/BasicRegistration/BasicRegistration";
import CompanyDataRegistration from "../Registration/CompanyDataRegistration/CompanyDataRegistration";
import CompanyAddressRegistration from "../Registration/CompanyAddressRegistration/CompanyAddressRegistration";
import ImageRegistration from "../Registration/ImageRegistration/ImageRegistration";
import BillingRegistration from "../Registration/BillingRegistration/BillingRegistration";
import classNames from "classnames/bind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/pro-light-svg-icons";
import {connect, ConnectedProps} from "react-redux";
import {Client, handleApiError} from "../../services/ApiService";
import {setMaxFinishedStep} from '../../stores/registration/registrationSlice';
import {setVendorID} from '../../stores/member/memberSlice';
import {toast} from "react-toastify";
import Loading from "../Loading/Loading";
import {navigate} from "gatsby";

const mapState = ({member, registration, auth, cookie}) => ({
    member,
    registration,
    auth,
    cookie
})

const mapDispatch = {
    setMaxFinishedStep,
    setVendorID
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

const StepMenu: ({
                     member,
                     registration,
                     auth,
                     setMaxFinishedStep,
                     setVendorID,
                     cookie
                 }: { member: any; registration: any; auth: any; setMaxFinishedStep: any; setVendorID: any; cookie: any }) => JSX.Element = ({member, registration, auth, setMaxFinishedStep, setVendorID, cookie}) => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (auth.Key) {
            setCurrentStep(2);
        }
        if (registration.maxFinishedStep) {
            setCurrentStep(registration.maxFinishedStep);
        }
    }, [])

    const testData = [
        {
            stepIndex: 1,
            title: 'Benutzer',
            finished: false
        },
        {
            stepIndex: 2,
            title: 'Firmendaten',
            finished: false
        },
        {
            stepIndex: 3,
            title: 'Firmenadresse',
            finished: false
        },
        {
            stepIndex: 4,
            title: 'Bilder',
            finished: false
        },
        // {
        //     stepIndex: 5,
        //     title: 'Rechtliches',
        //     finished: false
        // },
        // {
        //     stepIndex: 6,
        //     title: 'Plan',
        //     finished: false
        // },
        // {
        //     stepIndex: 6,
        //     title: 'AGB',
        //     finished: false
        // },
        // {
        //     stepIndex: 7,
        //     title: 'Zahlung',
        //     finished: false
        // },
    ]

    function getCurrentStepComponent(stepIndex: number) {
        switch (stepIndex) {
            case 1:
                return <BasicRegistration loadingCallback={setLoading} onComplete={() => {
                    setCurrentStep(2);
                    if (currentStep > registration.maxFinishedStep) {
                        setMaxFinishedStep(currentStep + 1)
                    }
                }}/>
            case 2:
                return <CompanyDataRegistration loadingCallback={setLoading} onComplete={() => {
                    setCurrentStep(3)
                    if (currentStep > registration.maxFinishedStep) {
                        setMaxFinishedStep(currentStep + 1)
                    }
                }}/>
            case 3:
                return <CompanyAddressRegistration loadingCallback={setLoading} onComplete={() => {
                    setCurrentStep(4)
                    if (currentStep > registration.maxFinishedStep) {
                        setMaxFinishedStep(currentStep + 2)
                    }
                }}/>
            case 4:
                return <ImageRegistration onComplete={() => {
                    registerVendor()
                    // setCurrentStep(5)
                    // if (currentStep > registration.maxFinishedStep) {
                    //     setMaxFinishedStep(currentStep + 1)
                    // }
                }}/>
            // case 5:
            //     return <LegalRegistration onComplete={() => {
            //         setCurrentStep(6)
            //         if (currentStep > registration.maxFinishedStep) {
            //             setMaxFinishedStep(currentStep + 1)
            //         }
            //     }}/>
            // case 6:
            //     return <PlanRegistration onComplete={() => {
            //         setCurrentStep(7)
            //         if (currentStep > registration.maxFinishedStep) {
            //             setMaxFinishedStep(currentStep + 1)
            //         }
            //     }}/>
            // case 6:
            //     return <AGBRegistration onComplete={() => {
            //         registerVendor()
            //         // setCurrentStep(7);
            //         // if (currentStep > registration.maxFinishedStep) {
            //         //     setMaxFinishedStep(currentStep + 1)
            //         // }
            //     }}/>
            case 7:
                return <BillingRegistration onComplete={() => {
                    setCurrentStep(8)
                    if (currentStep > registration.maxFinishedStep) {
                        setMaxFinishedStep(currentStep + 1)
                    }
                    registerVendor()
                }}/>
            case 8:
                return <>
                    <h2>Vielen Dank für deine Bewerbung!</h2>
                    <p> Du wirst schon bald von uns hören! </p>
                </>
            default:
                return <BasicRegistration loadingCallback={setLoading} onComplete={() => setCurrentStep(2)}/>
        }
    }

    function gtag_report_conversion(url) {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        // @ts-ignore
        gtag('event', 'conversion', {
            'send_to': 'AW-10887418845/L2LKCNGmqbUDEN2nw8co',
            'event_callback': callback
        });
        return false;
    }


    function registerVendor() {
        setLoading(true);
        Client.Member.registerVendor(auth.Key, member.Member.MemberID ? member.Member.MemberID : member.MemberID, registration.CompanyName, registration.shortDesc, registration.longDesc, registration.Web, registration.Email, registration.Phone, registration.AGBs, registration.ROW, registration.UID, registration.Country, registration.ZipCode, registration.Location, registration.Street, registration.StreetNr, registration.headerImage, registration.logo, registration.PlanID).then((res) => {
            setVendorID(res.data.ID);
            setTimeout(() => {
                Client.Auth.authPatch(auth.Key, 'Vendor', res.data.ID, registration.Category).catch(handleApiError);
                Client.Vendor.addImages(auth.Key, res.data.ID, registration.fotos).then(() => {
                    toast.success('Bewerbung erfolgreich!')
                    // @ts-ignore
                    if (cookie.PerformanceCookies) {

                        gtag_report_conversion(window.location)
                    }

                    setLoading(false)
                    navigate('/registrationSuccess');
                    // Client.Auth.authGetWithAction(auth.Key, 'Vendor', member.VendorID, 'onboardingLink').then((res) => {
                    // })
                }).catch((error) => {
                    handleApiError(error)
                    setLoading(false);
                });
            }, 500)
        }).catch((error) => {
            handleApiError(error);
            setLoading(false);
        });
    }

    let cl = classNames.bind(style);
    return (
        <Row className={style.stepRow}>
            <Col lg={3} md={12} className="d-flex align-items-start pb-5 pb-lg-0" data-spy="affix">
                <Row className={style.extraRow}>
                    {testData.map((item: any, index: number) => {
                        return (
                            <Col lg={12} md={6} xs={12} className={style.step + ' ' + cl({
                                clickableStep: item.stepIndex <= registration.maxFinishedStep,
                                notClickableStep: item.stepIndex > registration.maxFinishedStep || item.stepIndex === 1
                            })} onClick={() => {
                                setCurrentStep(item.stepIndex)
                                if (item.stepIndex > registration.maxFinishedStep) {
                                    setMaxFinishedStep(item.stepIndex + 1)
                                }
                            }} key={index}>
                                <div className={cl({
                                    stepIndexActive: index + 1 === currentStep,
                                    stepIndexInactive: index + 1 !== currentStep,
                                    stepIndexFinished: index + 1 < currentStep
                                })}>
                                    {item.stepIndex < currentStep ?
                                        <FontAwesomeIcon icon={faCheck} className={style.finishedIcon}/> :
                                        <span> {item.stepIndex} </span>
                                    }

                                </div>
                                <div className={style.stepName}>
                                    {item.title}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Col>
            <Col lg={{span: 8, offset: 1}} className={style.formCol}>
                {loading ? <Loading type={"border"} message={"Bitte warte kurz, wir bereiten alles für dich vor..."}/> :
                    <> {getCurrentStepComponent(currentStep)} </>
                }
            </Col>
        </Row>
    )
}

export default connector(StepMenu);
