import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/pro-solid-svg-icons";
// @ts-ignore
import * as style from './CompanyDataRegistration.module.scss';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
// @ts-ignore
import * as mainStyle from "../../../theme/main.module.scss";
import {connect, ConnectedProps} from "react-redux";
import {Client, handleApiError} from "../../../services/ApiService";
import "yup-phone";
import {
    setAddSuggestion,
    setCategory,
    setCategorySuggestion,
    setCompanyName,
    setEmail,
    setLongDesc,
    setPhone,
    setShortDesc,
    setUID,
    setWeb
} from "../../../stores/registration/registrationSlice";
import {MultiSelect} from "react-multi-select-component";
import Loading from "../../Loading/Loading";
import "./dropdown.scss";

const mapState = ({member, registration}) => ({
    member,
    registration
})

const mapDispatch = {
    setCompanyName,
    setPhone,
    setEmail,
    setWeb,
    setUID,
    setShortDesc,
    setLongDesc,
    setCategory,
    setCategorySuggestion,
    setAddSuggestion
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
    onComplete?(): void,
    loadingCallback(loadingState): void,
}

interface FormInputs {
    CompanyName: string,
    CompanyPhone: string,
    CompanyEmail: string,
    CompanyWebsite: string,
    CompanyUID: string,
    CompanyLongDesc: string,
    CategorySuggestion: string,
    CompanyCategory: any,
    UserCategory: boolean
}

const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const schema = yup.object().shape({
    CompanyName: yup.string().required('Bitte gib einen Firmennamen an.'),
    CompanyPhone: yup.string().optional().phone("AT", true, 'Bitte gib eine gültige Telefonnummer an.'),
    CompanyEmail: yup.string().required('Bitte gib eine E-Mail-Adresse an.').email('Bitte gib eine gültige E-Mail Adresse an.'),
    CompanyWebsite: yup.string().optional(),
    CompanyUID: yup.string().optional(),
    CompanyLongDesc: yup.string().required('Bitte gib eine Beschreibung an.'),
    UserCategory: yup.boolean().optional(),
    CategorySuggestion: yup.string().when('UserCategory', {
        is: true,
        then: yup.string().required("Bitte gib eine Kategorie an."),
        otherwise: yup.string().nullable()
    }),
    CompanyCategory: yup.array().when('UserCategory', {
        is: false,
        then: yup.array().required('Bitte wähle eine Kategorie aus.'),
        otherwise: yup.array().nullable()
    }),
})

const CompanyDataRegistration: FC<Props> = (props) => {
    const {register, handleSubmit, formState: {errors}, setValue} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    const [categories, setCategories] = useState([]);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [suggestCategory, setSuggestCategory] = useState(false);
    const [suggestedCategory, setSuggestedCategory] = useState('');

    useEffect(() => {
        Client.Raw.get('Category?filter[ParentID]=0').then((res) => {
            setCategories(res.data);
            res.data.forEach((cat) => {
                options.push({
                    label: cat.Title,
                    value: cat.ID
                })

            })
        }).catch(handleApiError)
        setSuggestCategory(props.registration.AddSuggestion);
        setSuggestedCategory(props.registration.CategorySuggestion);
    }, [])


    function onSubmit(data: FormInputs) {
        props.loadingCallback(true);
        let catsToSubmit = [];
        selected.forEach((selection) => {
            catsToSubmit.push(selection.value);
        })
        props.setCompanyName(data.CompanyName)
        props.setPhone(data.CompanyPhone)
        props.setEmail(data.CompanyEmail)
        props.setWeb(data.CompanyWebsite)
        props.setUID(data.CompanyUID)
        props.setLongDesc(data.CompanyLongDesc)
        props.setCategory(catsToSubmit)
        props.setCategorySuggestion(data.CategorySuggestion)
        props.loadingCallback(false);
        props.onComplete();
    }

    useEffect(() => {
        if (selected.length) {
            setValue('CompanyCategory', selected);
        }
    }, [selected])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {props.member ?
                <Row className={style.row}>
                    <h2> Firmendaten </h2>
                    <p>
                        Um deine Anfrage möglichst schnell beantworten zu können, möchten wir dich bitten möglichst alle
                        Felder auszufüllen
                    </p>
                    <Col lg={12} md={6} xs={12}>
                        <Form.Group controlId="formCompanyName" className="mb-2">
                            <Form.Label className={style.inputLabel}>Firmenname*</Form.Label>
                            <Form.Control type="text" className={style.inputField}
                                          defaultValue={props.registration.CompanyName} {...register('CompanyName')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.CompanyName?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={12} md={6} xs={12}>
                        <Form.Group controlId="formTel">
                            <Form.Label className={style.inputLabel}>Telefonnummer*</Form.Label>
                            <Form.Control type="text" className={style.inputField}
                                          defaultValue={props.registration.Phone} {...register('CompanyPhone')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.CompanyPhone?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={12} md={6} xs={12}>
                        <Form.Group controlId="formUID">
                            <Form.Label className={style.inputLabel}>UID</Form.Label>
                            <Form.Control type="text" className={style.inputField}
                                          defaultValue={props.registration.UID} {...register('CompanyUID')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.CompanyUID?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={12} md={6} xs={12}>
                        <Form.Group controlId="formEmail">
                            <Form.Label className={style.inputLabel}>E-Mail*</Form.Label>
                            <Form.Control type="email" className={style.inputField}
                                          defaultValue={props.registration.Email} {...register('CompanyEmail')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.CompanyEmail?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={12} md={6} xs={12}>
                        <Form.Group controlId="formWeb">
                            <Form.Label className={style.inputLabel}>Website</Form.Label>
                            <Form.Control type="text" className={style.inputField}
                                          defaultValue={props.registration.Web} {...register('CompanyWebsite')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.CompanyWebsite?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    {!suggestCategory ?
                        <Col lg={12} md={6} xs={12}>
                            <Form.Label className={style.inputLabel}>Kategorie(n)*</Form.Label>
                            <MultiSelect
                                className={style.dropDown}
                                options={options}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Kategorieauswahl"
                            />
                            <Form.Control type={"hidden"} value={selected.length}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.CompanyCategory?.message}
                            </Form.Text>
                        </Col> :
                        <Col lg={12} md={6} xs={12}>
                            <Form.Group controlId="formSuggestion">
                                <Form.Label className={style.inputLabel}>Kategorien-Vorschlag*</Form.Label>
                                <Form.Control type="text" className={style.inputField}
                                              onChange={(event) => setSuggestedCategory(event.target.value)}
                                              defaultValue={props.registration.CategorySuggestion ? props.registration.CategorySuggestion : ''} {...register('CategorySuggestion')}/>
                                <Form.Text className={mainStyle.errorMsg}>
                                    {errors.CategorySuggestion?.message}
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    }
                    <Col lg={12} md={6} xs={12} className={"py-2"}>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Ist deine Kategorie nicht dabei?"
                                        defaultChecked={props.registration.AddSuggestion}
                                        className={style.checkbox}
                                        onClick={() => {
                                            setSuggestCategory(!suggestCategory)
                                            props.setAddSuggestion(!suggestCategory)
                                        }}
                                        {...register('UserCategory')}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={12} md={12} xs={12}>
                        <Form.Group controlId="formLongInfo">
                            <Form.Group controlId="formLongInfo" className="mb-2">
                                <Form.Label className={style.inputLabel}>Beschreibung*</Form.Label>
                                <Form.Control as="textarea" rows={8}
                                              className={style.textArea}
                                              defaultValue={props.registration.longDesc}  {...register('CompanyLongDesc')}/>
                                <Form.Text className={mainStyle.errorMsg}>
                                    {errors.CompanyLongDesc?.message}
                                </Form.Text>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col lg={12} md={6} xs={12}>
                        <Button type="submit" className={style.submitButton}>
                            <FontAwesomeIcon icon={faUserCircle} className={style.icon}/>
                            Weiter
                        </Button>
                    </Col>
                </Row> : <Loading type={"border"} message={"Daten werden geladen"}/>
            }
        </Form>
    )
}

export default connector(CompanyDataRegistration);
