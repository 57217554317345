import * as React from "react";
import {FC, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// @ts-ignore
import * as style from './CompanyAddressRegistration.module.scss';
import {faCheck} from "@fortawesome/pro-light-svg-icons";
import * as yup from "yup";
// @ts-ignore
import * as mainStyle from "../../../theme/main.module.scss";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect, ConnectedProps} from "react-redux";
import {
    setCountry,
    setLocation,
    setStreet,
    setStreetNr,
    setZipCode,
} from '../../../stores/registration/registrationSlice';
import Loading from "../../Loading/Loading";

const mapState = ({registration, member}) => ({
    registration,
    member
})

const mapDispatch = {
    setStreet,
    setStreetNr,
    setZipCode,
    setLocation,
    setCountry,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
    onComplete?(): void,
    loadingCallback(loadingState): void
}


interface FormInputs {
    CompanyStreet: string,
    CompanyStreetNumber: string,
    CompanyZIP: string,
    CompanyCity: string,
    CompanyLand: string,
}

const schema = yup.object().shape({
    CompanyStreet: yup.string().required('Bitte gib eine Straße an.'),
    CompanyStreetNumber: yup.string().required('Bitte gib eine Hausnummer an.'),
    CompanyZIP: yup.string().required('Bitte gib eine Postleitzahl an.'),
    CompanyCity: yup.string().required('Bitte gib eine Stadt an.'),
    CompanyLand: yup.string().required('Bitte wähle ein Land aus.'),
})
const CompanyAddressRegistration: FC<Props> = (props) => {
    const [plzError, setPlZError] = useState(false);

    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    function onSubmit(data: FormInputs) {
        props.loadingCallback(true);
        props.setStreet(data.CompanyStreet);
        props.setStreetNr(data.CompanyStreetNumber);
        props.setZipCode(data.CompanyZIP);
        props.setLocation(data.CompanyCity);
        props.setCountry(data.CompanyLand);
        props.loadingCallback(false);
        props.onComplete();
    }

    return (
        <>
            <h2 className="mb-3"> Firmenadresse </h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {props.member ?
                    <Row>
                        <Col lg={12} md={6} xs={12}>
                            <Form.Group controlId="formStreet" className="mb-2">
                                <Form.Label className={style.inputLabel}>Straße*</Form.Label>
                                <Form.Control type="text" className={style.inputField}
                                              defaultValue={props.registration.Street} {...register('CompanyStreet')}/>
                                <Form.Text className={mainStyle.errorMsg}>
                                    {errors.CompanyStreet?.message}
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={6} xs={12}>
                            <Form.Group controlId="formHouseNr">
                                <Form.Label className={style.inputLabel}>Hausnummer*</Form.Label>
                                <Form.Control type="text"
                                              className={style.inputField}
                                              defaultValue={props.registration.StreetNr} {...register('CompanyStreetNumber')}/>
                                <Form.Text className={mainStyle.errorMsg}>
                                    {errors.CompanyStreetNumber?.message}
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={6} xs={12}>
                            <Form.Group controlId="formZip">
                                <Form.Label className={style.inputLabel}>PLZ*</Form.Label>
                                <Form.Control type="text" className={style.inputField}
                                              defaultValue={props.registration.ZipCode} {...register('CompanyZIP')}/>
                                <Form.Text className={mainStyle.errorMsg}>
                                    {errors.CompanyZIP?.message}
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={6} xs={12}>
                            <Form.Group controlId="formOrt">
                                <Form.Label className={style.inputLabel}>Ort*</Form.Label>
                                <Form.Control type="text" className={style.inputField}
                                              defaultValue={props.registration.Location} {...register('CompanyCity')}/>
                                <Form.Text className={mainStyle.errorMsg}>
                                    {errors.CompanyCity?.message}
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={6} xs={12}>
                            <Form.Group controlId="formRegion">
                                <Form.Label className={style.inputLabel}>Land*</Form.Label>
                                <div className={style.dropDownChevron}>
                                    <Form.Control as="select" className={style.dropDown} {...register('CompanyLand')}>
                                        <option value={"AT"}> Österreich</option>
                                    </Form.Control>
                                    <Form.Text className={mainStyle.errorMsg}>
                                        {errors.CompanyLand?.message}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                            <Button type="submit" className={style.submitButton}>
                                <FontAwesomeIcon icon={faCheck} className={style.icon}/>
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                    : <Loading type={"border"} message={"Daten werden geladen"}/>}
            </Form>
        </>
    )
}

export default connector(CompanyAddressRegistration);
