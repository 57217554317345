import * as React from "react";
import {FC} from "react";
import UploadField from "../../UploadField/UploadField";
import {Button} from "react-bootstrap";
// @ts-ignore
import * as style from './ImageRegistration.module.scss';

type Props = {
    onComplete(): void,
}

const ImageRegistration: FC<Props> = (props) => {
    return (
        <>
            <h2>Bilder sagen mehr als 1000 Worte</h2>
            <p>Damit wir uns ein besseres Bild machen können, füge uns gerne ein paar aussagekräftige Fotos von dir, deinem Unternehmen und deinen Produkten hinzu.</p>
            <div className={"my-3"}>
                <h5>Header-Image</h5>
            </div>
            <UploadField maxFiles={1} hasLogo={false}/>
            <div className={"my-3"}>
                <h5>Logo</h5>
            </div>
            <UploadField maxFiles={1} hasLogo={true}/>
            <div className={"my-3"}>
                <h5>Weitere Bilder</h5>
            </div>
            <UploadField hasLogo={false}/>
            <Button onClick={props.onComplete} className={style.submitButton}>
                Weiter
            </Button>
        </>
    )
}

export default ImageRegistration;
