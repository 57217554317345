// extracted by mini-css-extract-plugin
export var clickableStep = "StepMenu-module--clickableStep--D7OX5";
export var extraRow = "StepMenu-module--extraRow--14VLb";
export var finishedIcon = "StepMenu-module--finishedIcon--CDdb-";
export var notClickableStep = "StepMenu-module--notClickableStep--DPneP";
export var step = "StepMenu-module--step--l1CvY";
export var stepIndexActive = "StepMenu-module--stepIndexActive--gtrXg";
export var stepIndexFinished = "StepMenu-module--stepIndexFinished--ZK6UN";
export var stepIndexInactive = "StepMenu-module--stepIndexInactive--pnp2X";
export var stepName = "StepMenu-module--stepName--FWA55";
export var stepRow = "StepMenu-module--stepRow--oxPWJ";