import * as React from "react";
import {FC} from "react";
import {Button} from "react-bootstrap";

type Props = {
    onComplete():void,
}

const BillingRegistration: FC<Props> = (props) => {
    return (
        <Button onClick={() => props.onComplete()}>
            Finished
        </Button>
    )
}

export default BillingRegistration;