import * as React from "react";
import StepMenu from "../StepMenu/StepMenu";
// @ts-ignore
import * as style from './VendorRegistration.module.scss';

const VendorRegistration = () => {
    return (
        <>
            <div className={style.registrationHolder} >
                <div className={style.headingHolder + ' text-center'}>
                    <h1>Händler-Bewerbung</h1>
                </div>
            </div>
            <div className={style.container}>
                <StepMenu/>
            </div>
        </>
    )
}

export default VendorRegistration;
