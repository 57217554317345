import * as React from "react";
import Layout from "../../../components/Navigation/layout";
import VendorRegistration from "../../../components/VendorRegistration/VendorRegistration";
import {Container} from "react-bootstrap";

const IndexPage = () => {
    return (
        <Layout pageTitle="Händler-Bewerbung">
            <VendorRegistration/>
        </Layout>
    )
}

export default IndexPage;
